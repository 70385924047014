import ContactForm from "components/ContactForm/ContactForm";
import Layout from "modules/Layout/Layout";
import FAQ from "modules/ServicesDetail/FAQ/FAQ";
import Intro from "modules/ServicesDetail/Intro/Intro";
import Stages from "modules/ServicesDetail/Stages/Stages";
import WhyChooseUs from "modules/ServicesDetail/WhyChooseUs/WhyChooseUs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import axiosInstance from "./api/config";

function ServiceDetail() {
  const { id } = useParams();
  const [page, setPage] = useState<any | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      axiosInstance.get(`/api/v1/services/${id}/`).then((response) => {
        setPage(response.data);
      });
      // try {
      //   const response = await getServicesDetailData(Number(id)); // Assuming getServicesDetailData takes an id parameter
      //   setPage(response.data);
      // } catch (error) {
      //   console.error("Error fetching about data:", error);
      // }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    console.log(page);
  }, [page]);

  return (
    <Layout>
      {page?.title && (
        <>
          <Helmet>
            <title>{page.title}</title>
            <meta name="description" content={page.description} />
          </Helmet>
          <Intro
            title={page.title}
            description={page.description}
            image={page.image}
          />
          <WhyChooseUs title={page.why_us_title} whys={page.why_uses} />

          <Stages title={page.stage_title} stages={page.stages} />
          <FAQ title={page.faq_title} faqs={page.faqs} />
          <ContactForm />
        </>
      )}
    </Layout>
  );
}

export default ServiceDetail;
