import React, { useEffect, useState } from "react";
import Layout from "../modules/Layout/Layout";
import { Helmet } from "react-helmet";
import Intro from "modules/Intro/Intro";
import ServicesList from "modules/ServicesList/ServicesList";
import ContactForm from "components/ContactForm/ContactForm";
import axiosInstance from "./api/config";
import Image_1 from "assets/services/image_1.png";
import Image_2 from "assets/services/image_2.png";

const ServicesPage = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/api/v1/services/cards/")
      .then((response) => setServices(response.data));
  }, []);
  return (
    <Layout>
      <Helmet>
        <title>Dev Consulting | Services</title>
        <meta
          name="description"
          content={
            "Our comprehensive solutions drive enduring industry evolution, strategically poised to tackle the everyday obstacles hindering your business. With our expertise, we navigate your challenges, ensuring sustainable growth and innovation at every turn."
          }
        />
      </Helmet>
      <Intro
        title="IT solutions, from idea to realization"
        title_underlined={"IT solutions"}
        images={[Image_1, Image_2]}
        description={
          "Our comprehensive solutions drive enduring industry evolution, strategically poised to tackle the everyday obstacles hindering your business. With our expertise, we navigate your challenges, ensuring sustainable growth and innovation at every turn."
        }
      />
      <ServicesList title="Our services" services={services} />
      <ContactForm />
    </Layout>
  );
};

export default ServicesPage;
