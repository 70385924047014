// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Mobile devices (max-width: 480px) */
/* Tablet devices (max-width: 768px) */
/* Laptop devices (max-width: 1024px) */
/* Desktop (min-width: 1025px) */
.styles_header__m-oRC {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
  font-size: 0.875rem;
}
@media (max-width: 768px) {
  .styles_header__m-oRC {
    padding: 1.5rem 0.5rem;
  }
  .styles_header__m-oRC > a > img {
    width: 164px;
    height: 20.35px;
  }
}

.styles_nav__22\\+B2 {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
@media (max-width: 768px) {
  .styles_nav__22\\+B2 > a {
    display: none;
  }
}

.styles_button__HVDUU {
  padding: 10px 24px;
}`, "",{"version":3,"sources":["webpack://./src/styles/mixins.scss","webpack://./src/modules/Layout/Header/styles.module.scss"],"names":[],"mappings":"AAAA,sCAAA;AAOA,sCAAA;AAOA,uCAAA;AAOA,gCAAA;AClBA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;EAEA,mBAAA;AACF;ADDE;ECNF;IASI,sBAAA;EAEF;EADE;IACE,YAAA;IACA,eAAA;EAGJ;AACF;;AACA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;AAEF;ADhBE;ECiBE;IACE,aAAA;EAEJ;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":["/* Mobile devices (max-width: 480px) */\n@mixin mobile {\n  @media (max-width: 480px) {\n    @content;\n  }\n}\n\n/* Tablet devices (max-width: 768px) */\n@mixin tablet {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n/* Laptop devices (max-width: 1024px) */\n@mixin laptop {\n  @media (max-width: 1024px) {\n    @content;\n  }\n}\n\n/* Desktop (min-width: 1025px) */\n@mixin desktop {\n  @media (min-width: 1025px) {\n    @content;\n  }\n}\n\n@mixin hide-scrollbar {\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n","@import \"../../../styles/variables.scss\";\n@import \"../../../styles/mixins.scss\";\n\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 1.5rem 0;\n\n  font-size: 0.875rem;\n\n  @include tablet {\n    padding: 1.5rem 0.5rem;\n    & > a > img {\n      width: 164px;\n      height: 20.35px;\n    }\n  }\n}\n\n.nav {\n  display: flex;\n  align-items: center;\n  gap: 1.5rem;\n\n  @include tablet {\n    & > a {\n      display: none;\n    }\n  }\n}\n\n.button {\n  padding: 10px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `styles_header__m-oRC`,
	"nav": `styles_nav__22+B2`,
	"button": `styles_button__HVDUU`
};
export default ___CSS_LOADER_EXPORT___;
