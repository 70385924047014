import React from "react";
import classes from "./styles.module.scss";
import Title from "ui/Title/Title";

const Intro = ({
  title,
  title_underlined,
  description,
  images,
}: {
  title: string;
  title_underlined: string;
  description: string;
  images: string[];
}) => {
  return (
    <div className={classes.intro}>
      <div>
        <Title underlined={title_underlined} title={title} />
        <p
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </div>
      <div className={classes.images}>
        {images.map((image) => (
          <img
            src={image}
            key={image}
            alt="Company  1"
            className={classes.image}
          />
        ))}
      </div>
    </div>
  );
};

export default Intro;
