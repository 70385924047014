import React, { useEffect, useState } from "react";
import Layout from "../modules/Layout/Layout";
import { Helmet } from "react-helmet";
import Intro from "modules/Home/HomeIntro/Intro";
import ServicesList from "modules/ServicesList/ServicesList";
// import { getHomeData } from "./api/home.api";
// import Projects from "modules/Home/Projects/Projects";
import ContactForm from "components/ContactForm/ContactForm";
import CalculatePriceForm from "components/CalculatePriceForm/CalculatePriceForm";
import axiosInstance from "./api/config";

const HomePage: React.FC = () => {
  const [services, setServices] = useState([]);
  // const [page, setPage] = useState<HomePageInterface | null>(null);

  useEffect(() => {
    axiosInstance
      .get("/api/v1/services/cards/")
      .then((response) => setServices(response.data));
  }, []);

  return (
    <Layout>
      <>
        <Helmet>
          <title>Dev Consulting</title>
          <meta name="description" content={"Description"} />
        </Helmet>
        <Intro
          subtitle={
            "Your trusted partner for \nsoftware development solutions."
          }
        />
        {services && (
          <ServicesList
            title="We are experts in these services"
            services={services}
          />
        )}
        {/* <Projects /> */}
        <CalculatePriceForm />
        <ContactForm />
      </>
    </Layout>
  );
};

export default HomePage;
