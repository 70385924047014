import React from "react";
import classes from "./styles.module.scss";
import { WhyInterface } from "./interface";

const WhyItem: React.FC<{ why: WhyInterface }> = ({ why }) => {
  return (
    <div className={classes.whyItem}>
      <div className={classes.whyIconWrapper}>
        {why.image && (
          <img src={why.image} alt={why.title} className={classes.whyIcon} />
        )}
      </div>
      <div className={classes.whyText}>
        <div className={classes.whyTitle}>{why.title}</div>
        <div className={classes.whyDescription}>{why.description}</div>
      </div>
    </div>
  );
};

export default WhyItem;
