import React, { useEffect, useState } from "react";
import Layout from "../modules/Layout/Layout";
import { Helmet } from "react-helmet";
import Intro from "modules/Intro/Intro";
import Company from "modules/About/Company/Company";
import WhyUs from "modules/About/WhyUs/WhyUs";
import ContactForm from "components/ContactForm/ContactForm";
import axiosInstance from "./api/config";
import Image_1 from "assets/about/image_1.png";
import Image_2 from "assets/about/image_2.png";

const AboutPage = () => {
  const [whys, setWhys] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/api/v1/services/why-us/")
      .then((response) => setWhys(response.data));
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>About Dev Consulting</title>
        <meta
          name="description"
          content={
            "We leverage our deep expertise in software development to offer comprehensive consultancy services, assisting clients in navigating the complexities of technology implementation and optimization. With a focus on collaboration and innovation, we empower organizations to stay ahead in today's rapidly evolving digital landscape. "
          }
        />
      </Helmet>
      <Intro
        title="Company that works for your business"
        title_underlined="business"
        description="We leverage our deep expertise in software development to offer comprehensive consultancy services, assisting clients in navigating the complexities of technology implementation and optimization. With a focus on collaboration and innovation, we empower organizations to stay ahead in today's rapidly evolving digital landscape. "
        images={[Image_1, Image_2]}
      />
      <WhyUs why_us={whys} />
      <Company />
      <ContactForm />
    </Layout>
  );
};

export default AboutPage;
