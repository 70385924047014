import React from "react";
import classes from "./styles.module.scss";
import Title from "ui/Title/Title";
import WhyItem from "./WhyItem";
import { WhyInterface } from "./interface";

const WhyChooseUs: React.FC<{ whys: WhyInterface[]; title: string }> = ({
  whys,
  title,
}) => {
  return (
    <div className={classes.whys}>
      <Title title={title} />
      <div className={classes.whyList}>
        {whys.map((item: WhyInterface) => (
          <WhyItem why={item} key={item.title} />
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
