import Title from "ui/Title/Title";
import classes from "./styles.module.scss";
import Image from "./images/image.png";

const Company = () => {
  return (
    <div className={classes.company}>
      <Title title="Our company" />
      <div className={classes.companyContent}>
        <img className={classes.companyImage} src={Image} alt="company" />
        <div className={classes.companyDescription}>
          <p>
            Dev consulting is a forward-thinking consultancy firm based in
            Latvia, specializing in providing top-tier consulting services and
            technical assistance to clients locally and internationally.
            Established by a diverse team of seasoned professionals, DC has been
            a trusted partner since its inception, delivering tailored solutions
            to address development challenges across various sectors.
          </p>
          <br />
          <p>
            Dev Consulting, we aspire to be leaders in the Latvian and regional
            consultancy landscape, renowned for our commitment to quality,
            innovation, and delivering measurable impact for our clients.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Company;
