// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Mobile devices (max-width: 480px) */
/* Tablet devices (max-width: 768px) */
/* Laptop devices (max-width: 1024px) */
/* Desktop (min-width: 1025px) */
.styles_container__6bisI {
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/mixins.scss","webpack://./src/modules/Layout/styles.module.scss"],"names":[],"mappings":"AAAA,sCAAA;AAOA,sCAAA;AAOA,uCAAA;AAOA,gCAAA;AClBA;EACE,cAAA;EACA,iBAAA;EACA,WAAA;AAEF","sourcesContent":["/* Mobile devices (max-width: 480px) */\n@mixin mobile {\n  @media (max-width: 480px) {\n    @content;\n  }\n}\n\n/* Tablet devices (max-width: 768px) */\n@mixin tablet {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n/* Laptop devices (max-width: 1024px) */\n@mixin laptop {\n  @media (max-width: 1024px) {\n    @content;\n  }\n}\n\n/* Desktop (min-width: 1025px) */\n@mixin desktop {\n  @media (min-width: 1025px) {\n    @content;\n  }\n}\n\n@mixin hide-scrollbar {\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n","@import \"../../styles/variables.scss\";\n@import \"../../styles/mixins.scss\";\n\n.container {\n  margin: 0 auto;\n  max-width: 1280px;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__6bisI`
};
export default ___CSS_LOADER_EXPORT___;
