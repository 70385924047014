// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Mobile devices (max-width: 480px) */
/* Tablet devices (max-width: 768px) */
/* Laptop devices (max-width: 1024px) */
/* Desktop (min-width: 1025px) */
.styles_servicesWrapper__KNTvs {
  display: block;
  padding: 64px 0;
  overflow: hidden;
}
@media (max-width: 768px) {
  .styles_servicesWrapper__KNTvs {
    padding: 24px 0;
  }
}

.styles_servicesList__A7Twd {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  row-gap: 48px;
}
@media (max-width: 768px) {
  .styles_servicesList__A7Twd {
    display: flex;
    height: 370px;
    align-items: center;
    padding: 0 15px;
    width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .styles_servicesList__A7Twd::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/mixins.scss","webpack://./src/modules/ServicesList/styles.module.scss"],"names":[],"mappings":"AAAA,sCAAA;AAOA,sCAAA;AAOA,uCAAA;AAOA,gCAAA;AClBA;EACE,cAAA;EAEA,eAAA;EACA,gBAAA;AACF;ADCE;ECNF;IAOI,eAAA;EAEF;AACF;;AACA;EACE,aAAA;EACA,kCAAA;EACA,SAAA;EACA,aAAA;AAEF;ADXE;ECKF;IAOI,aAAA;IACA,aAAA;IACA,mBAAA;IACA,eAAA;IAEA,WAAA;IACA,kBAAA;IDOF,qBAAA;IACA,wBAAA;ECJA;EDFA;IACE,QAAA;IACA,SAAA;ECIF;AACF","sourcesContent":["/* Mobile devices (max-width: 480px) */\n@mixin mobile {\n  @media (max-width: 480px) {\n    @content;\n  }\n}\n\n/* Tablet devices (max-width: 768px) */\n@mixin tablet {\n  @media (max-width: 768px) {\n    @content;\n  }\n}\n\n/* Laptop devices (max-width: 1024px) */\n@mixin laptop {\n  @media (max-width: 1024px) {\n    @content;\n  }\n}\n\n/* Desktop (min-width: 1025px) */\n@mixin desktop {\n  @media (min-width: 1025px) {\n    @content;\n  }\n}\n\n@mixin hide-scrollbar {\n  &::-webkit-scrollbar {\n    width: 0;\n    height: 0;\n  }\n\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n}\n","@import \"../../styles/variables.scss\";\n@import \"../../styles/mixins.scss\";\n\n.servicesWrapper {\n  display: block;\n\n  padding: 64px 0;\n  overflow: hidden;\n\n  @include tablet {\n    padding: 24px 0;\n  }\n}\n\n.servicesList {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 16px;\n  row-gap: 48px;\n\n  @include tablet {\n    display: flex;\n    height: 370px;\n    align-items: center;\n    padding: 0 15px;\n\n    width: 100%;\n    overflow-x: scroll;\n\n    @include hide-scrollbar;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"servicesWrapper": `styles_servicesWrapper__KNTvs`,
	"servicesList": `styles_servicesList__A7Twd`
};
export default ___CSS_LOADER_EXPORT___;
