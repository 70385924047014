import { Component } from "react";
import { IntroInterface } from "./interface";
import classes from "./styles.module.scss";
import Title from "ui/Title/Title";

class Intro extends Component<IntroInterface> {
  render() {
    const { title, image, description } = this.props;

    return (
      <div className={classes.intro}>
        <div>
          <Title title={title} />
          <p
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <img key={title} src={image} alt={title} className={classes.image} />
      </div>
    );
  }
}

export default Intro;
